<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="80%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('order.form.claim.title') }} {{ order.name }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('order.form.claim.subtitle', order) }}
          </div>
        </template>

        <v-form ref="claim" v-model="isValid" lazy-validation autocomplete="off" @change="validate()">
          <v-row>
            <v-col cols="3">
              <v-select v-model="discountTarget" item-value="id" item-text="name" :items="discountTargets" :loading="loading"
                        required dense hide-details style="width:95%" @change="validate() && updateDiscount()"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="discountAmount" type="number" :rules="discountAmountRules" required dense hide-details solo
                            :label="$t('order.form.claim.input.discountAmount.label')" :clearable="true" @change="validate() && updateDiscount()"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="discountPercentage" type="number" :rules="discountPercentageRules" required dense hide-details solo
                            :label="$t('order.form.claim.input.discountPercentage.label')" :clearable="true" @change="validate() && updateDiscount()"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="expirationInDays" type="number" :rules="expirationInDaysRules" required dense hide-details solo
                            :label="$t('order.form.claim.input.expirationInDays.label')" :clearable="true"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea id="answer" v-model="answer" :counter="answerMaxLength" :rules="answerRules" required
                          :label="$t('order.form.claim.input.answer.label')" :clearable="true"
                          dense auto-grow rows="4" row-height="25" autofocus
              />
            </v-col>
            <v-col cols="12">
              <v-btn color="red" @click.stop="insertText($t('order.form.claim.template.sorry.text', order))">
                {{ $t('order.form.claim.template.sorry.name') }}
              </v-btn>
              <v-btn color="red" @click.stop="insertText($t('order.form.claim.template.error.text', order))">
                {{ $t('order.form.claim.template.error.name') }}
              </v-btn>
              <v-btn color="red" @click.stop="insertText($t('order.form.claim.template.forget.text', order))">
                {{ $t('order.form.claim.template.forget.name') }}
              </v-btn>
              <v-btn color="red" @click.stop="insertText($t('order.form.claim.template.service.text'))">
                {{ $t('order.form.claim.template.service.name') }}
              </v-btn>
              <v-btn color="red" @click.stop="insertText($t('order.form.claim.template.generosity.text'))">
                {{ $t('order.form.claim.template.generosity.name') }}
              </v-btn>
              <v-btn color="red" @click.stop="insertText($t('order.form.claim.template.late.text'))">
                {{ $t('order.form.claim.template.late.name') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" @click.stop="insertText($t('order.form.claim.template.cold.text'))">
                {{ $t('order.form.claim.template.cold.name') }}
              </v-btn>
              <v-btn color="primary" @click.stop="insertText($t('order.form.claim.template.product.text'))">
                {{ $t('order.form.claim.template.product.name') }}
              </v-btn>
              <v-btn color="primary" @click.stop="insertText($t('order.form.claim.template.bread.text'))">
                {{ $t('order.form.claim.template.bread.name') }}
              </v-btn>
              <v-btn color="primary" @click.stop="insertText($t('order.form.claim.template.fries.text'))">
                {{ $t('order.form.claim.template.fries.name') }}
              </v-btn>
              <v-btn color="primary" @click.stop="insertText($t('order.form.claim.template.book.text'))">
                {{ $t('order.form.claim.template.book.name') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn color="secondary" @click.stop="insertText($t('order.form.claim.template.seeYouSoon.text'))">
                {{ $t('order.form.claim.template.seeYouSoon.name') }}
              </v-btn>
              <v-btn color="secondary" @click.stop="insertText($t('order.form.claim.template.haveANiceDay.text'))">
                {{ $t('order.form.claim.template.haveANiceDay.name') }}
              </v-btn>
              <v-btn color="secondary" @click.stop="insertText($t('order.form.claim.template.haveANiceEvening.text'))">
                {{ $t('order.form.claim.template.haveANiceEvening.name') }}
              </v-btn>
              <v-btn color="secondary" @click.stop="insertText($t('order.form.claim.template.haveANiceWeekEnd.text'))">
                {{ $t('order.form.claim.template.haveANiceWeekEnd.name') }}
              </v-btn>
              <v-btn color="secondary" @click.stop="insertText($t('order.form.claim.template.haveANiceSunday.text'))">
                {{ $t('order.form.claim.template.haveANiceSunday.name') }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn color="close" @click.stop="reset()">
                {{ $t('order.form.claim.button.close') }}
              </v-btn>
              <v-btn :disabled="!isValid || loading" :loading="loading" color="accent" @click.stop="validate() && sendClaim()">
                {{ $t('order.form.claim.button.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </base-material-card>
    </v-dialog>
    <v-btn small rounded color="senary" :loading="false" :disabled="false" @click.stop="show=true">
      <v-icon left>
        mdi-percent
      </v-icon>{{ $t('order.button.claim') }}
    </v-btn>
  </v-row>
</template>

<script>
  import { DateTime } from 'luxon';

  export default {
    name: 'ClaimOrderForm',
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        alert: '',
        loading: false,
        show: false,
        isValid: true,
        answer: '',
        discountTarget: 'restaurant',
        // discountTargets: [{ id: 'market', name: 'Marché' }, { id: 'restaurant', name: 'Restaurant' }],
        discountTargets: [{ id: 'restaurant', name: 'Restaurant' }],
        discountAmount: undefined,
        discountAmountMin: 1,
        discountAmountMax: 100,
        discountAmountRules: [
          v => !v || (v && v >= this.discountAmountMin) || this.$t('order.form.claim.input.discountAmount.rule.min', { min: this.discountAmountMin }),
          v => !v || (v && v <= this.discountAmountMax) || this.$t('order.form.claim.input.discountAmount.rule.max', { max: this.discountAmountMax })
        ],
        discountPercentage: undefined,
        discountPercentageMin: 5,
        discountPercentageMax: 100,
        discountPercentageRules: [
          v => !v || (v && v >= this.discountPercentageMin) || this.$t('order.form.claim.input.discountPercentage.rule.min', { min: this.discountPercentageMin }),
          v => !v || (v && v <= this.discountPercentageMax) || this.$t('order.form.claim.input.discountPercentage.rule.max', { max: this.discountPercentageMax })
        ],
        expirationInDays: 30,
        expirationInDaysMin: 7,
        expirationInDaysMax: 365,
        expirationInDaysRules: [
          v => !v || (v && v >= this.expirationInDaysMin) || this.$t('order.form.claim.input.expirationInDays.rule.min', { min: this.expirationInDaysMin }),
          v => !v || (v && v <= this.expirationInDaysMax) || this.$t('order.form.claim.input.expirationInDays.rule.max', { max: this.expirationInDaysMax })
        ],
        answerMinLength: 50,
        answerMaxLength: 5000,
        answerRules: [
          v => !!v || this.$t('order.form.claim.input.answer.rule.required'),
          v => (v && v.length >= this.answerMinLength) || this.$t('order.form.claim.input.answer.rule.minLength', { count: this.answerMinLength }),
          v => (v && v.length <= this.answerMaxLength) || this.$t('order.form.claim.input.answer.rule.maxLength', { count: this.answerMaxLength })
        ]
      };
    },
    mounted: async function () {
      if (DateTime.utc().setZone('Europe/Paris').hour >= 18) {
        this.answer = 'Bonsoir,\n\n';
      } else {
        this.answer = 'Bonjour,\n\n';
      }
    },
    methods: {
      insertText (text) {
        const answer = document.getElementById('answer');
        const startPosition = answer.selectionStart;
        const endPosition = answer.selectionEnd;

        if (startPosition && endPosition) {
          this.answer = this.answer.substring(0, startPosition) + text + '\n\n' + this.answer.substring(endPosition, this.answer.length);
        } else {
          this.answer += text + '\n\n';
        }
      },
      updateDiscount () {
        const expirationDate = DateTime.utc().setZone('Europe/Paris').plus({ days: this.expirationInDays }).set({ hour: 23, minute: 59 }).toFormat('dd/MM/yyyy');
        const target = this.discountTarget === 'market' ? ' du Marché' : '';
        if (this.discountAmount) {
          this.insertText(this.$i18n.t('order.form.claim.template.discountAmount.text', { amount: Math.abs(Number(this.discountAmount)) * -1, target: target, expiration: expirationDate }));
        } else if (this.discountPercentage) {
          this.insertText(this.$i18n.t('order.form.claim.template.discountPercentage.text', { amount: Math.abs(Number(this.discountPercentage)) * -1, target: target, expiration: expirationDate }));
        }
      },
      validate () {
        this.isValid = this.$refs.claim.validate();

        return this.isValid;
      },
      async sendClaim () {
        this.loading = true;
        try {
          let value = -20;
          let valueType = 'percentage';
          if (this.discountAmount) {
            value = Math.abs(this.discountAmount) * -1;
            valueType = 'fixed_amount';
          } else if (this.discountPercentage) {
            value = Math.abs(this.discountPercentage) * -1;
            valueType = 'percentage';
          }

          await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'customer/claim/send', {
            orderId: this.order.id,
            target: this.discountTarget,
            value: value,
            valueType: valueType,
            expirationInDays: this.expirationInDays,
            answer: this.answer
          });
          this.$emit('success', this.order.id);
          this.reset();
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('order.error'));
        }
        this.loading = false;
      },
      reset () {
        this.show = false;
        this.discountTarget = 'restaurant';
        this.discountAmount = undefined;
        this.discountPercentage = undefined;
        this.expirationInDays = 30;
        this.answer = 'Bonjour,\n\n';
      }
    }
  };
</script>
